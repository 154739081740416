import Vue from 'vue'
import Vuex from 'vuex'

import api from '@/services/api'
import router from '@/router'
import util from '@/services/util'
import storageUtil from '@/services/storageUtil'
import surveyJsUtil from '@/services/surveyJs/util'

Vue.use(Vuex)

let loadingCount = 0

export default new Vuex.Store({
  state: {
    cati: false, // true => is Cati Module (Visual Basic App)
    dataId: null,
    error: null,
    loading: false,
    pw: null,
    snackbar: {
      enabled: false,
      texts: []
    },
    startupData: null,
    startupDataMap: {},
    survey: null,
    surveys: [],
    surveyJsMapping: {},
    surveyVersion: null,
    source: null,
    timeoutKey: null,
    version: null
    //variables: null
  },
  mutations: {
    setCati(state, data) {
      state.cati = data
    },
    setDataId(state, data) {
      state.dataId = data
    },
    setError(state, data) {
      state.error = data
    },
    setLoading(state, data) {
      state.loading = data
    },
    setPw(state, data) {
      state.pw = data
    },
    setStartupData(state, data) {
      state.startupData = data
    },
    setSurveyVersion(state, version) {
      state.surveyVersion = version
    },
    setSource(state, data) {
      state.source = data
    },
    setSurvey(state, data) {
      state.survey = data
    },
    setTimeoutKey(state, data) {
      state.timeoutKey = data
    },
    setVersion(state, data) {
      state.version = data
    }
    /*setVariables(state, data) {
      state.variables = data
    }*/
  },
  actions: {
    addSnackbarText({ state }, text) {
      if (!state.snackbar.enabled) {
        state.snackbar.texts = []
        state.snackbar.enabled = true
      } 
      state.snackbar.texts.push(text)
    },
    async getStartupData({ commit, state }) {
      let key = state.source + '-!-' + state.survey.name
      let startupData = state.startupDataMap[key]

      if (!startupData) {
        startupData = (await api.call('getStartupData', {
          vars: {
            source: state.source,
            survey: state.survey.name,
            id: state.dataId,
            pw: state.pw
          }
        })).data

        state.startupDataMap[key] = startupData
      }

      commit('setStartupData', startupData)

      return startupData
    },
    async getSurvey({ state, commit }, surveyName) {
      if (state.survey && (state.survey.name == surveyName)) {
        return  
      }

      let survey = (await api.call('getSurvey', {
        vars: {
          source: state.source,
          survey: surveyName,
          id: state.dataId,
          pw: state.pw
        },
        params: {
          surveyVersion: state.surveyVersion,
          timeoutKey: storageUtil.getTimeoutKey(),
          version: state.version
        }
      })).data.survey

      document.title = survey.label

      surveyJsUtil.applyStyle({
        theme: survey.theme || "defaultV2" // themes are: "defaultV2", "modern", 'legacy' 
      })

      commit('setSurvey', survey)
    },
    getSurveyJsMapping: util.AsyncPromiseQueue(async ({ state }, params) => {
      let key = state.source + '/' + state.survey.name + '/' + params.collectionName 

      if (state.surveyJsMapping[key]) {
        return state.surveyJsMapping[key]
      } else {
        let mapping = (await api.call('getSurveyJsMapping', {
          vars: {
            source: state.source,
            survey: state.survey.name,
            collectionName: params.collectionName
          }
        })).data

        state.surveyJsMapping[key] = mapping

        return mapping
      }
    }),
    initializeApp({ commit }) {
      // Use window.location.search to get query parameters directly
      const urlParams = new URLSearchParams(window.location.search)
      const surveyVersion = urlParams.get('surveyVersion')

      commit('setSurveyVersion', surveyVersion)
    },
    setCati({ commit }, cati) {
      commit('setCati', cati)
    },
    setTimeoutKey({ commit }, timeoutKey) {
      commit('setTimeoutKey', timeoutKey)
    },
    /*async getVariables({ state, commit }) {
      let variables = (await api.call('getVariables', {
        vars: {
          survey: state.survey.name,
          source: state.source
        }
      })).data

      commit('setVariables', variables)
    },*/
    setDataId({ commit }, dataId) {
      commit('setDataId', dataId)
    },
    setError({ commit }, error) {
      commit('setError', error)
    },
    setPw({ commit }, pw) {
      commit('setPw', pw)
    },
    setSource({ commit }, source) {
      commit('setSource', source)
    },
    setSurveys({ commit }, surveys) {
      commit('setSurveys', surveys)
    },
    setVersion({ commit }, version) {
      commit('setVersion', version)
    },
    triggerLoading({ commit }, show) {
      loadingCount += show ? 1 : -1

      commit('setLoading', loadingCount > 0)
    }
  },
  modules: {
  }
})
